import React, { useState } from 'react';
import SelecteerTicketsBalk from '../components/SelecteerTicketsBalk';
import Weersverwachting from '../components/Weersverwachting';

function Tickets() {
    const [date, setDate] = useState(new Date().toISOString())

    const updateDate = (date) => {
        setDate(date);
    }

    return (
        <>
            <SelecteerTicketsBalk updateTicketDate={updateDate} />
            <Weersverwachting ticketDate={date}/>
        </>
    )
}

export default Tickets