import "../stylesheets/SelecteerTicketsBalk.css"
//import DatePicker from 'react-date-picker'
import React, { useEffect, useState } from "react";

function SelecteerTicketsBalk(props){

    const [adultAmount, setAdultAmount] = useState(0);
    const [childrenAmount, setChildrenAmount] = useState(0);
    const [price, setPrice] = useState(0);
    const [date, setDate] = useState(new Date())

    const updateAdultAmount = (amount) => {
        setAdultAmount(amount);
    }

    const updateChildrenAmount = (amount) => {
        setChildrenAmount(amount)
    }

    const updateDate = (date) => {
        setDate(date);
    }
    
    const calculatePrice = () => {
        var newPrice = adultAmount * 15 + childrenAmount * 10;
        setPrice(newPrice);
    }

    useEffect(calculatePrice, [adultAmount, childrenAmount]);
    useEffect(() => props.updateTicketDate(date), [props, date])

    

    return (
        <>
        <div id="SelecteerTicketsBalk">
            <DatePicker onChange={updateDate} />
            <AdultAmount onChange={updateAdultAmount}/>
            <ChildrenAmount onChange={updateChildrenAmount} />
            <PricePlaceholder totalPrice={price} />
        </div>
        <button id="orderTickets">Bestel tickets</button>
        </>
    )
}

function DatePicker(props) {
    const [date, setDate] = useState(new Date().toISOString().substring(0, 10))

    const handleChange = (event) => {
        var date = event.target.value;
        setDate(date);
        props.onChange(date);
    }

    return (
        <>
            <div className="BalkItem">
                <label htmlFor="ticketDate">Selecteer dag</label>
                <input type="date" id="ticketDate" value={date} onChange={handleChange}></input>
            </div>
        </>
    )
}

function AdultAmount(props) {
    const[amount, setAmount] = useState(0);

    const handleChange = (event) => {
        var value = event.target.value;
        if(value < 0) {
            value = 0;
        }
        setAmount(value);
        props.onChange(value);
    }

    return (
        <>
            <div className="BalkItem">
                <label htmlFor="ticketAmountAdult">Aantal volwassenen</label>
                <input type="number" id="ticketAmountAdult" onChange={handleChange} value={amount}></input>
            </div>
        </>
    )    
}

function ChildrenAmount(props) {
    const[amount, setAmount] = useState(0);

    const handleChange = (event) => {
        var value = event.target.value;
        if(value < 0) {
            value = 0;
        }
        setAmount(value);
        props.onChange(value);
    }

    return (
        <>
            <div className="BalkItem">
                <label htmlFor="ticketAmountChildren">Aantal kinderen</label>
                <input type="number" id="ticketAmountChildren" onChange={handleChange} value={amount}></input>
            </div>
        </>
    )
}

function PricePlaceholder(props) {
    return (
        <>
            <div className="BalkItem">
                € <span id="TicketPricePlaceholder">{props.totalPrice}</span>
            </div>
        </>
    )
}

export default SelecteerTicketsBalk;