import { useEffect, useState } from "react"
import "../stylesheets/weersverwachting.css"

export default function Weersverwachting(props) {
    const [minTemp, setMinTemp] = useState(0)
    const [maxTemp, setMaxTemp] = useState(0)
    const [tempUnit, setTempUnit] = useState("C")
    

    const RequestWeatherForecastForOneDay = () => {
        const date = new Date(props.ticketDate);
        var dateString = date.getFullYear() + "-" + (1 + date.getMonth()).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "https://api.open-meteo.com/v1/forecast?latitude=52.08&longitude=4.30&daily=temperature_2m_max,temperature_2m_min,precipitation_sum,rain_sum&current_weather=true&timezone=auto&start_date=" + dateString + "&end_date=" + dateString);
        xhr.send();

        xhr.onload = () => {
            if(xhr.status === 200) {
                var data = JSON.parse(xhr.responseText);
                var daily = data.daily
                var temp_unit = data.daily_units.temperature_2m_max;
                setMinTemp(daily.temperature_2m_min);
                setMaxTemp(daily.temperature_2m_max);
                if(temp_unit !== tempUnit) {
                    setTempUnit(temp_unit);
                }
            }
        }
    }
    useEffect(RequestWeatherForecastForOneDay, [props.ticketDate, tempUnit])
    return (
        <>
        <div id="Weersverwachting">
            <h2>Weersverwachting Den Haag</h2>
            <div id="WeersverwachtingContent">
                <p>Datum: <span id="WeatherDate">{new Date(props.ticketDate).toDateString()}</span></p>
                <p>Min temperatuur: <span id="WeatherMinTemp">{minTemp} {tempUnit}</span></p>
                <p>Max temperatuur: <span id="WeatherMaxTemp">{maxTemp} {tempUnit}</span></p>
            </div>
        </div>
        </>
    )

    
}

