import '../stylesheets/navbar.css';
import { Outlet, Link } from "react-router-dom"

function Navbar()
{
    return (
        <>
        <div id="navbar">
            <div id="logo">Pretpark Den Haag</div>
            <div className="menubuttonscontainer">
                    <button className="menubutton" id="homeButton">
                        <Link to="/">Home</Link>
                    </button>
                    <button className="menubutton" id="overOnsButton">
                        <Link to="/tickets">Tickets</Link>
                    </button>
                </div>
        </div>

        <Outlet />
        </>
    );
}

export default Navbar;


// <button className="menubutton" id="ticketButton">Tickets</button>